import React, { FunctionComponent, useEffect, useRef, useState } from 'react';

import { useParseQueryParameters } from 'application/hooks/use-parameters';
import { useSelector } from 'react-redux';
import { useDispatch } from 'store';
import { replaceWhitespaces } from 'application/utils';
import {
  selectExternalTowers,
  selectLatLngTowers,
  selectTowers,
  selectTowersWithNotes,
  Tower,
} from 'store/selectors/tower.selectors';
import { selectNetworkGroups } from 'store/selectors/farm-infra.selectors';
import { selectLatLngTowers as selectInfrastructureLatLngTowers } from 'store/selectors/infrastructure.selectors';
import towerEffects from 'store/effects/tower.effects';
import { v4 as uuid } from 'uuid';
import { ICreateTowerRequestDTO, IUpdateTowerRequestDTO } from '@halter-corp/tower-service-client';
import TopographyService from 'services/topography.service';
import farmInfraEffects from 'store/effects/farm-infra.effects';
import FarmInfraService from 'services/farm-infra-service';
import { ICreateNetworkGroupFarmMappingRequestDTO } from '@halter-corp/farm-infra-service-client';
import infrastructureEffects from 'store/effects/infrastructure.effects';
import { MapUtils } from 'application/utils/MapUtils';
import { ITowerProductEnum } from '@halter-corp/infrastructure-service-client';
import InstallPlanScreen from '../screens/install-plan.screen';
import { ParsedTower, parsePasteData } from '../util';
import { useNewTowersContext } from '../contexts/new-tower-context';
import { useExistingTowersContext } from '../contexts/existing-tower-context';
import { useInvalidInputContext } from '../contexts/invalid-input-context';
import { useExternalTowersContext } from '../contexts/external-tower-context';

const InstallPlannerContainer: FunctionComponent = () => {
  const EXTERNAL_TOWER_RADIUS = 10000;

  const dispatch = useDispatch();

  const { farmId } = useParseQueryParameters();
  const towers = useSelector(selectTowers);
  const infrastructureTowers = useSelector(selectInfrastructureLatLngTowers);
  const infrastructureTowersById = MapUtils.keyBy(infrastructureTowers, (it) => it.name);
  const metadata = TopographyService.useFarmMapMetadata(farmId);
  const latLngTowers = useSelector(selectLatLngTowers);
  const externalTowers = useSelector(selectExternalTowers);
  const towersWithNotes = useSelector(selectTowersWithNotes);
  const networkGroups = useSelector(selectNetworkGroups);
  const { setNewTowerMap } = useNewTowersContext();
  const { setExternalTowerMap } = useExternalTowersContext();
  const { setExistingTowerMap } = useExistingTowersContext();
  const { setInvalidInput } = useInvalidInputContext();
  const markerRefs = useRef(new Map());
  const [warningMessage, setWarningMessage] = useState({ open: false, content: '' });

  const handleRemoveTower = (towerId: string) => {
    setNewTowerMap((current) => {
      current.delete(towerId);
      return new Map(current);
    });
    markerRefs.current.delete(towerId);
  };

  const handleCreateTowerFarmMapping = async (towerId: string) => {
    const createTowerFarmMappingRequest = { farmId, towerId };
    await dispatch(towerEffects.saveTowerFarmMapping({ createTowerFarmMappingRequest }));
    dispatch(towerEffects.fetchTowersByFarmId({ farmId }));

    const infrastructureTower = infrastructureTowersById.get(towerId);
    if (infrastructureTower == null) {
      throw new Error(`Could not find infrastructure tower with name: ${towerId}`);
    }
    createTowerFarmMappingRequest.towerId = infrastructureTower.id;
    dispatch(infrastructureEffects.createTowerFarmMapping({ createTowerFarmMappingRequest }));
  };

  const handleRemoveTowerFarmMapping = async (towerId: string) => {
    await dispatch(towerEffects.deleteTowerFarmMappingByFarmIDAndTowerID({ farmId, towerId }));
    dispatch(towerEffects.fetchTowersByFarmId({ farmId }));

    const infrastructureTower = infrastructureTowersById.get(towerId);
    if (infrastructureTower == null) {
      throw new Error(`Could not find infrastructure tower with name: ${towerId}`);
    }
    dispatch(
      infrastructureEffects.deleteTowerFarmMappingByFarmIdAndTowerId({
        farmId,
        towerId: infrastructureTower.id,
      })
    );
  };

  const handleSaveNetworkGroupFarmMapping = async (networkGroupId: string) => {
    if (networkGroups.includes(networkGroupId)) {
      return;
    }

    const createNetworkGroupFarmMappingRequest: ICreateNetworkGroupFarmMappingRequestDTO = {
      farmId,
      networkGroupId,
    };
    const result = await dispatch(
      farmInfraEffects.saveNetworkGroupFarmMapping({ createNetworkGroupFarmMappingRequest })
    );
    if (result?.payload == null) {
      setWarningMessage({
        open: true,
        content: `Could not create network group farm mapping for network group ${networkGroupId}.`,
      });
    }
  };

  const handleSaveInfrastructureTower = async (tower: Tower) => {
    const infrastructureTowerId = uuid();
    const createTowerRequest = {
      id: infrastructureTowerId,
      name: tower.id,
      networkGroupId: tower.networkGroupId,
      location: tower.location,
    };
    await dispatch(infrastructureEffects.createTower({ createTowerRequest }));
    const updateTowerMetadataRequest = {
      router: tower.router,
      starlink: tower.starlink,
      mainsPowered: tower.mainsPowered,
      product: ITowerProductEnum.T4,
    };
    dispatch(
      infrastructureEffects.updateTowerMetadataById({
        id: infrastructureTowerId,
        updateTowerMetadataRequest,
      })
    );
    const createTowerFarmMappingRequest = {
      farmId,
      towerId: infrastructureTowerId,
    };
    dispatch(infrastructureEffects.createTowerFarmMapping({ createTowerFarmMappingRequest }));
  };

  const handleGetOrSaveNetworkGroup = async () => {
    const emptyNetworkGroups = networkGroups.filter((networkGroup) => {
      const networkGroupTowersInRange = latLngTowers.filter(
        (latLngTower) => latLngTower.networkGroupId === networkGroup
      );
      return networkGroupTowersInRange == null || networkGroupTowersInRange.length === 0;
    });

    if (emptyNetworkGroups != null && emptyNetworkGroups.length !== 0) {
      return emptyNetworkGroups[0];
    }

    const networkGroupId = await FarmInfraService.saveNetworkGroup();
    if (networkGroupId == null) {
      setWarningMessage({
        open: true,
        content: `Could not create network group. Please try again.`,
      });
      return null;
    }
    return networkGroupId;
  };

  const handleSaveTower = async (tower: Tower) => {
    if (tower.mainsPowered == null || tower.router == null || tower.starlink == null) {
      setInvalidInput((current) => {
        current.set(tower.id, true);
        return new Map(current);
      });
      return Promise.resolve({ payload: null });
    }

    if (tower.router === true) {
      const networkGroup = await handleGetOrSaveNetworkGroup();
      if (networkGroup == null) {
        return null;
      }
      // eslint-disable-next-line no-param-reassign
      tower.networkGroupId = networkGroup;
    } else if (!tower.networkGroupId && tower.routerTowerId) {
      const externalRouterTowers = latLngTowers.filter(
        (latLngTower) => latLngTower.id === tower.routerTowerId
      );
      if (externalRouterTowers == null || externalRouterTowers.length === 0) {
        setWarningMessage({
          open: true,
          content: `Could not find router tower with Id: ${tower.routerTowerId}.`,
        });
        return null;
      }

      const { networkGroupId } = externalRouterTowers[0];
      // eslint-disable-next-line no-param-reassign
      tower.networkGroupId = networkGroupId;
    }

    handleSaveNetworkGroupFarmMapping(tower.networkGroupId);

    const createTowerRequest: ICreateTowerRequestDTO = {
      id: tower.id,
      networkGroupId: tower.networkGroupId,
      farmId: tower.farmId,
      location: tower.location,
      mainsPowered: tower.mainsPowered,
      router: tower.router,
      starlink: tower.starlink,
    };

    const createdTowerPayload = await dispatch(towerEffects.saveTower({ createTowerRequest }));
    if (createdTowerPayload.payload != null) {
      handleSaveInfrastructureTower(tower);
    }
    return createdTowerPayload;
  };

  const handleUpdateInfrastructureTower = async (tower: Tower) => {
    const infrastructureTower = infrastructureTowersById.get(tower.id);
    if (infrastructureTower == null) {
      throw new Error(`Could not find infrastructure tower with name: ${tower.id}`);
    }

    const updateTowerRequest = {
      networkGroupId: tower.networkGroupId,
      location: tower.location,
    };
    dispatch(
      infrastructureEffects.updateTowerById({
        id: infrastructureTower.id,
        updateTowerRequest,
      })
    );
    const updateTowerMetadataRequest = {
      router: tower.router,
      starlink: tower.starlink,
      mainsPowered: tower.mainsPowered,
    };
    dispatch(
      infrastructureEffects.updateTowerMetadataById({
        id: infrastructureTower.id,
        updateTowerMetadataRequest,
      })
    );
  };

  const handleUpdateTower = async (tower: Tower) => {
    const updateTowerRequest: IUpdateTowerRequestDTO = {
      location: tower.location,
      mainsPowered: tower.mainsPowered,
      router: tower.router,
      starlink: tower.starlink,
    };
    await dispatch(towerEffects.updateTowerById({ towerId: tower.id, updateTowerRequest }));
    handleUpdateInfrastructureTower(tower);
  };

  const handleDeleteTower = (towerId: string) => {
    dispatch(towerEffects.deleteTowerById({ towerId }));

    const infrastructureTower = infrastructureTowersById.get(towerId);
    if (infrastructureTower == null) {
      throw new Error(`Could not find infrastructure tower with name: ${towerId}`);
    }
    dispatch(infrastructureEffects.deleteTowerById({ id: infrastructureTower.id }));
  };

  const handlePastedTowers = (e: ClipboardEvent) => {
    const parsedPasteData = parsePasteData(e.clipboardData!.getData('text'));

    parsedPasteData.forEach((el: ParsedTower) => {
      const newTower: Tower = {
        id: replaceWhitespaces(el.name.trim(), '-'),
        farmId,
        networkGroupId: '',
        location: {
          latitude: el.lat,
          longitude: el.lng,
        },
        mainsPowered: el.starlink,
        router: el.router,
        starlink: el.starlink,
        notes: [],
        groupIndex: el.groupIndex,
        routerTowerId: el.routerTower,
      };

      setNewTowerMap((current) => {
        current.set(newTower.id, newTower);
        return new Map(current);
      });
    });
  };

  useEffect(() => {
    setNewTowerMap(new Map());
    setExternalTowerMap(new Map());
    setExistingTowerMap(new Map());
    dispatch(towerEffects.fetchTowersByFarmId({ farmId }));
    dispatch(farmInfraEffects.fetchNetworkGroupsByFarmId({ farmId }));
  }, [farmId]);

  useEffect(() => {
    const farmBounds = metadata?.bounds;
    if (farmBounds === undefined) {
      return;
    }
    const total: { lat: number; lng: number } = { lat: 0, lng: 0 };
    farmBounds.forEach((latlng) => {
      total.lat += latlng[0];
      total.lng += latlng[1];
    });
    const avg: { lat: number; lng: number } = {
      lat: total.lat / farmBounds.length,
      lng: total.lng / farmBounds.length,
    };
    dispatch(
      towerEffects.fetchAllTowersByRadiusAndLatLng({
        latitude: avg.lat,
        longitude: avg.lng,
        radius: EXTERNAL_TOWER_RADIUS,
      })
    );
    dispatch(
      infrastructureEffects.fetchTowersByLatLngAndRadius({
        latitude: avg.lat,
        longitude: avg.lng,
        radius: EXTERNAL_TOWER_RADIUS,
      })
    );
  }, [towers, metadata]);

  useEffect(() => {
    setExternalTowerMap(new Map(externalTowers.map((tower) => [tower.id, { ...tower, notes: [] }])));
  }, [externalTowers]);

  useEffect(() => {
    dispatch(towerEffects.fetchNotesByTowers({ towers }));
  }, [towers]);

  useEffect(() => {
    setExistingTowerMap(new Map(towersWithNotes.map((tower) => [tower.id, tower])));
  }, [towersWithNotes]);

  useEffect(() => {
    document.addEventListener('paste', handlePastedTowers);

    return () => document.removeEventListener('paste', handlePastedTowers);
  }, [farmId]);

  return (
    <InstallPlanScreen
      farmId={farmId}
      markerRefs={markerRefs}
      warningMessage={warningMessage}
      setWarningMessage={setWarningMessage}
      handleSaveTower={handleSaveTower}
      handleUpdateTower={handleUpdateTower}
      handleDeleteTower={handleDeleteTower}
      handleRemoveTower={handleRemoveTower}
      handleCreateTowerFarmMapping={handleCreateTowerFarmMapping}
      handleDeleteTowerFarmMapping={handleRemoveTowerFarmMapping}
    />
  );
};

export default InstallPlannerContainer;
