import { Column } from 'application/components/data-table';
import { IFarmDTO } from '@halter-corp/business-service-client';
import React from 'react';
import { Link } from 'react-router-dom';

import { FaultyDevice } from 'data/fleet-watch';
import { dateToText } from '../utils';

export const cowColumn: Column<{ cattleName?: string }> = {
  name: 'Cow',
  keyExtractor: (device) => {
    if (Number.isNaN(Number(device.cattleName)) || device.cattleName?.split('')[0] === '0') {
      return device.cattleName;
    }
    return Number(device.cattleName);
  },
  render: (device) => device.cattleName,
  allowCopyToClipboard: true,
};

export const collarColumn: Column<{ serialNumber?: string }> = {
  name: 'Serial number',
  keyExtractor: (device) => device.serialNumber,
  render: (device) => (
    <Link to={{ pathname: `/devices/${device.serialNumber}` }} target="_blank" style={{ color: 'black' }}>
      {device.serialNumber}
    </Link>
  ),
  allowCopyToClipboard: true,
};

export const firmwareVersionColumn: Column<{ firmwareVersion?: string }> = {
  name: 'Firmware Version',
  keyExtractor: (device) => device?.firmwareVersion,
  render: (device) => device?.firmwareVersion,
};

export const farmColumn = (farms: IFarmDTO[]): Column<{ deviceEui?: string; farmId?: string }> => ({
  name: 'Farm',
  keyExtractor: (device) => device?.deviceEui,
  render: (device: { farmId?: string }) => farms.find((farm) => farm.id === device.farmId)?.name,
});

export const mobColumn: Column<{ name?: string }> = {
  name: 'Mob',
  keyExtractor: (device) => device?.name,
  render: (device) => (device?.name == null ? 'Not Assigned' : device?.name),
};

export const updatedAtColumn: Column<{ updatedDate?: Date }> = {
  name: 'Last Update',
  keyExtractor: (device) => device?.updatedDate,
  render: (device) => <p style={{ color: '#696969' }}>{dateToText(device?.updatedDate)}</p>,
};

export const debugColumn: Column<{ serialNumber?: string }> = {
  name: 'Debug',
  render: (device) => (
    <Link
      to={{
        pathname: `https://halter.grafana.net/d/VQetH7i4z/collar-debug?var-serialNumber=${device.serialNumber}`,
      }}
      target="_blank"
      style={{ color: 'black', textDecoration: 'none', fontSize: '15px', textAlign: 'center' }}
    >
      🛠
    </Link>
  ),
};

const statusStrings = (device: FaultyDevice): string[] => {
  const statuses = [];
  if (device.status.swapRequested) statuses.push('Faulty (Manual)');
  else if (device.status.faulty) statuses.push('Faulty (Auto detect)');

  if (device.status.outOfBattery) statuses.push('Out of Battery');
  if (device.status.offline) statuses.push('Offline');
  if (device.status.fallenOffCow) statuses.push('Fallen off cow');
  return statuses;
};

export const statusColumn: Column<FaultyDevice> = {
  name: 'Status',
  keyExtractor: (device) => statusStrings(device).join(),
  render: (device) => (
    <p style={{ whiteSpace: 'pre', color: '#990000', margin: 0 }}>{statusStrings(device).join('\n')}</p>
  ),
};

const determineAppMessage = (device: FaultyDevice): string => {
  if (device.status.swapRequested || device.status.faulty) return 'Replace Collar • Collar Issue';
  if (device.status.poweredOff) return 'Powered off';
  if (device.status.outOfBattery) return 'Replace Collar • Critical Battery';
  if (device.status.offline) {
    const taggedOn = device.cattleId != null;
    return taggedOn ? 'Offline - Contact support' : 'Unknown';
  }
  if (device.status.fallenOffCow) return 'Collect Collar • Off cow';
  return '';
};

export const appMessageColumn: Column<FaultyDevice> = {
  name: 'App Message',
  keyExtractor: (device) => device.serialNumber,
  render: (device) => (
    <>
      <p style={{ fontStyle: 'italic', margin: 0 }}>{determineAppMessage(device)}</p>
      {!device.cattleName && <p style={{ fontStyle: 'italic', margin: 0 }}>(Hidden in App)</p>}
    </>
  ),
};

export const swapRequestColumn: Column<FaultyDevice> = {
  name: 'Swap Request',
  keyExtractor: (device) => device.status.swapRequested?.createdDate ?? '',
  render: (device) =>
    device.status.swapRequested ? (
      <>
        <p style={{ margin: 0 }}>{device.status.swapRequested.reason}</p>
        <span style={{ margin: '0', color: '#696969', fontSize: '12px' }}>
          {device.status.swapRequested.createdBy}{' '}
          {dateToText(new Date(device.status.swapRequested.createdDate))}
        </span>
      </>
    ) : (
      <></>
    ),
};
